html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

input[type="checkbox"] {
  margin: 5px;
}

label {
  margin-top: 5px;
}

.menu {
  position: absolute;
  box-sizing: border-box;
  background: white;
  border: 1px solid #cccccc;
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background-color: #4095bf;
}

/* We have some onClick links with no href - we want them to have pointers too */
a {
  cursor: pointer;
}

/* Make checkboxes bigger:
   https://stackoverflow.com/questions/306924/checkbox-size-in-html-css */
input[type="checkbox"] {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

button {
  width: 100%;
}

div.topAndBottomMargin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav > li > a {
  padding: 10px 7px;
}

.nav {
  width: 100%;
}

.bottomBorder {
  border-bottom: 1px solid #ddd;
}

.rt-td {
  text-align: center;
}

.app {
  margin-bottom: 10px;
}

